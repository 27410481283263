// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-gatsby-theme-blog-core-src-templates-post-query-js": () => import("./../../../node_modules/gatsby-theme-blog-core/src/templates/post-query.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-core-src-templates-post-query-js" */),
  "component---node-modules-gatsby-theme-blog-core-src-templates-posts-query-js": () => import("./../../../node_modules/gatsby-theme-blog-core/src/templates/posts-query.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-core-src-templates-posts-query-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

